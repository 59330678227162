import React, { memo } from 'react';
import propTypes from 'prop-types';

const ChatCover = ({ iframeURL, iframeRef, onLoad = () => { } }) => {
  return (
    <div className='ai-agent-chat-cover'>
      <iframe
        src={iframeURL}
        title='Embedded Agent'
        id="form-agent-helper"
        ref={iframeRef}
        onLoad={onLoad}
      />
    </div>
  );
};

export default memo(ChatCover);

ChatCover.propTypes = {
  iframeURL: propTypes.string.isRequired,
  iframeRef: propTypes.oneOfType([propTypes.func, propTypes.shape({})]).isRequired,
  onLoad: propTypes.func
};
