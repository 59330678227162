import { createRoot } from 'react-dom/client';
import React from 'react';
import App from './containers/App';
import AgentModalView from './containers/AgentModalView';

class EmbeddedAgentWrapper {
  #getRootElement = rootId => {
    let root = document.querySelector(`#${rootId}`);

    if (root) {
      return root;
    }

    root = document.createElement('div');
    root.id = rootId;
    document.body.appendChild(root);
    return root;
  };

  init = ({
    rootId, formID, domain, queryParams, background, variant, stopAnimation, isInitialOpen, isDraggable, username, buttonBackgroundColor, buttonIconColor, autoOpenChatIn, customizations,
    agentRenderURL
  }) => {
    const container = this.#getRootElement(rootId);
    const root = createRoot(container);
    root.render(<App
      formID={formID}
      domain={domain}
      queryParams={queryParams}
      background={background}
      variant={variant}
      stopAnimation={stopAnimation}
      isInitialOpen={isInitialOpen}
      isDraggable={isDraggable}
      username={username}
      buttonBackgroundColor={buttonBackgroundColor}
      buttonIconColor={buttonIconColor}
      autoOpenChatIn={autoOpenChatIn}
      customizations={customizations}
      agentRenderURL={agentRenderURL}
    />);
  };

  initModalView = ({
    rootId, formID, domain, queryParams
  }) => {
    const container = this.#getRootElement(rootId);
    const root = createRoot(container);
    root.render(<AgentModalView
      formID={formID}
      domain={domain}
      queryParams={queryParams}
    />);
  };
}

window.AgentInitializer = new EmbeddedAgentWrapper();
