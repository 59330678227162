import { useState, useEffect } from 'react';

const ANIMATION_STATES = {
  ENTERING: 'entering',
  ENTERED: 'entered',
  EXITING: 'exiting',
  EXITED: ''
};

const ANIMATION_DURATION = 300;

export const useAnimation = ({ duration = ANIMATION_DURATION, onEntered, onExited } = {}) => {
  const {
    ENTERING, ENTERED, EXITED, EXITING
  } = ANIMATION_STATES;
  const [animationState, setAnimationState] = useState(EXITED);

  const startAnimation = () => {
    setAnimationState(ENTERING);
  };

  const stopAnimation = () => {
    setAnimationState(EXITING);
  };

  const setAnimation = status => {
    if (status) {
      startAnimation();
    } else {
      stopAnimation();
    }
  };

  useEffect(() => {
    let timeout;

    if (animationState === ENTERING) {
      timeout = setTimeout(() => {
        setAnimationState(ENTERED);
        onEntered?.();
      }, duration);
    }

    if (animationState === EXITING) {
      timeout = setTimeout(() => {
        setAnimationState(EXITED);
        onExited?.();
      }, duration);
    }

    return () => clearTimeout(timeout);
  }, [animationState, duration, onEntered, onExited]);

  return {
    animationState,
    setAnimation,
    startAnimation,
    stopAnimation
  };
};
