import React from 'react';
import PropTypes from 'prop-types';
import { EmbeddedAgentToggleButton } from './EmbeddedAgentToggleButton';

const ChatButton = ({
  avatarURL = '',
  agentDesignProps = {},
  onClick,
  isTooltipShouldShown,
  customizations = {
    greeting: 'Yes',
    greetingMessage: 'Got any questions? Ask AI',
    pulse: 'Yes',
    position: 'right'
  }
}) => {
  const defaultAvatarURL = 'https://www.jotform.com/assets/img/ai-agent/default-avatar/default-avatar-head.png';
  const tooltipTitle = customizations?.greetingMessage || 'Hi! How can I assist you';

  const { background } = agentDesignProps;

  return (
    <div className="ai-agent-chat-avatar-container">
      <div className='ai-agent-avatar-content'>
        <div className='ai-agent-avatar-content-wrapper'>
          {
          isTooltipShouldShown && customizations?.greeting === 'Yes'
          && <div className='ai-agent-chat-avatar-tooltip'>{tooltipTitle}</div>
          }
          <div className='ai-agent-chat-avatar-pulse-wrapper'>
            <EmbeddedAgentToggleButton
              avatarURL={avatarURL}
              defaultAvatarURL={defaultAvatarURL}
              background={background}
              onClick={onClick}
            />
            {isTooltipShouldShown && customizations?.pulse === 'Yes' && (
            <>
              <span style={{ backgroundColor: background }} className='ai-agent-chat-avatar-pulse pulse-1' />
              <span style={{ backgroundColor: background }} className='ai-agent-chat-avatar-pulse pulse-2' />
            </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

ChatButton.propTypes = {
  avatarURL: PropTypes.string,
  agentDesignProps: PropTypes.shape({
    background: PropTypes.string
  }),
  onClick: PropTypes.func.isRequired,
  isTooltipShouldShown: PropTypes.bool.isRequired,
  customizations: PropTypes.shape({
    greeting: PropTypes.string,
    greetingMessage: PropTypes.string,
    pulse: PropTypes.string
  })
};

export default ChatButton;
