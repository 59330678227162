import { useEffect } from 'react';
import { useEventListener } from '@jotforminc/hooks';
import { useAnimation } from './useAnimation';
import { sentMessageToIframe } from '../utils/utils';

const useMaximize = ({ iframeRef }) => {
  const { animationState: isMaximized, setAnimation } = useAnimation({ duration: 100 });

  const classMapper = {
    entering: 'maximize-enter',
    entered: 'maximize-enter-active',
    exiting: 'maximize-exit',
    exited: ''
  };

  const mediaQuery = window?.matchMedia('(max-width: 480px)');

  const closeMaximize = () => {
    sentMessageToIframe(iframeRef, {
      action: 'set-maximize',
      value: false
    });
    setAnimation(false);
  };

  useEffect(() => {
    const handleMediaQuery = () => {
      const isMobile = mediaQuery.matches;
      sentMessageToIframe(iframeRef, {
        action: 'is-mobile',
        value: isMobile
      });
      if (isMaximized && isMobile) {
        closeMaximize();
      }
    };

    handleMediaQuery();
    mediaQuery.addEventListener('change', handleMediaQuery);
    return () => {
      mediaQuery.removeEventListener('change', handleMediaQuery);
    };
  }, [isMaximized, iframeRef.current]);

  useEventListener('message', ({ data }) => {
    const { action = '', value = false } = data || {};
    if (action === 'set-maximize') {
      setAnimation(value);
    }
  });

  return {
    isMaximized: classMapper[isMaximized],
    closeMaximize
  };
};

export default useMaximize;
