/* eslint-disable @jotforminc/no-native-button */
import React from 'react';
import PropTypes from 'prop-types';
import ChatCover from './ChatCover';

const ChatContainer = ({
  iframeURL, iframeRef, isOpened, loading, handleLoad
}) => {
  return (
    <div className={`${isOpened && !loading ? 'isOpened' : ''} ai-agent-chat-animation-container`}>
      <ChatCover onLoad={handleLoad} iframeURL={iframeURL} iframeRef={iframeRef} />
    </div>
  );
};

ChatContainer.propTypes = {
  iframeURL: PropTypes.string.isRequired,
  iframeRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({})]).isRequired,
  isOpened: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  handleLoad: PropTypes.func.isRequired
};

export default ChatContainer;
