/* eslint-disable @jotforminc/no-native-button */
import React, { useEffect, useState, useRef } from 'react';
import '../styles/main.scss';
import { string, array } from 'prop-types';
import { DotsLoader } from '@jotforminc/apploaders';
import { IconChevronDown } from '@jotforminc/svg-icons';
import { useAnimation } from '../hooks/useAnimation';
import ChatCover from '../components/ChatCover';

const AgentModalView = ({
  domain = 'https://www.jotform.com',
  formID,
  queryParams = []
}) => {
  const iframeRef = useRef();
  const iframeURL = `${domain}/agent/${formID}?${queryParams.join('&')}&parentURL=${encodeURIComponent(window.location.href)}&embedMode=lightbox`;
  const [loading, setLoading] = useState(true);
  const { animationState, startAnimation, stopAnimation } = useAnimation({ duration: 100 });

  useEffect(() => {
    startAnimation();
  }, []);

  const handleClose = () => {
    stopAnimation();
  };

  const handleOnLoad = () => {
    setLoading(false);
  };

  return (
    <div className={`ai-agent-modal-view ${animationState}`}>
      <div className="ai-agent-modal-content">
        <button onClick={handleClose} type='button' className='ai-agent-chat-close-btn'>
          <IconChevronDown />
        </button>
        {loading && (
          <div className='loading-wrapper'>
            <DotsLoader />
          </div>
        )}
        <ChatCover onLoad={handleOnLoad} iframeRef={iframeRef} iframeURL={iframeURL} />
      </div>
    </div>
  );
};

export default AgentModalView;

AgentModalView.propTypes = {
  domain: string,
  formID: string.isRequired,
  queryParams: array
};
