export const logAction = ({
  variant, target, action, actor
}) => {
  const executeAction = () => {
    let projectName = 'formCopilot';
    if (variant === '28422') {
      projectName = 'formCopilotAvatarClosed';
    }
    if (variant === '28432') {
      projectName = 'formCopilotAvatarTooltip';
    }

    if (window.JotFormActions) {
      const { tick } = window.JotFormActions(projectName);
      const isAdmin = window.JotForm?.isAdmin ?? false;

      if (!(window.location.href.includes('jotform.pro') || window.location.href.includes('jotform.ooo') || isAdmin)) {
        tick({
          actor, target, action
        });
      }
    }
  };

  if (typeof window.JotFormActions !== 'function') {
    const script = document.createElement('script');
    script.setAttribute('src', '//js.jotform.com/actions.js');
    document.querySelector('head').appendChild(script);

    script.addEventListener('load', executeAction);
  }

  if (window.JotFormActions) {
    executeAction();
  }
};

export const sentMessageToIframe = (iframeRef, value) => {
  const iframe = iframeRef.current;
  if (iframe && iframe.contentWindow) {
    iframe.contentWindow.postMessage(value, '*');
  }
};
