/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @jotforminc/no-native-button */
import React from 'react';
import { string, func } from 'prop-types';

export const EmbeddedAgentToggleButton = ({
  avatarURL = '',
  defaultAvatarURL = '',
  background = '',
  onClick
}) => {
  const [error, setError] = React.useState(false);
  return (
    <div onClick={onClick} className='ai-agent-chat-avatar' style={{ background: background }}>
      <img
        draggable={false}
        src={!error ? avatarURL : defaultAvatarURL}
        alt="agent"
        onError={() => setError(true)}
      />
    </div>
  );
};

EmbeddedAgentToggleButton.propTypes = {
  avatarURL: string,
  defaultAvatarURL: string,
  background: string,
  onClick: func.isRequired
};
