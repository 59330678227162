/* eslint-disable @jotforminc/no-native-button */
import React, {
  useState, useRef, useEffect, useCallback
} from 'react';
import {
  string, array, bool, number, shape
} from 'prop-types';
import { useEventListener } from '@jotforminc/hooks';
import { StorageHelper } from '@jotforminc/storage-helper';
import { ABTestManager } from '@jotforminc/abtest-manager';
import '../styles/main.scss';

import ChatButton from '../components/ChatButton';
import ChatContainer from '../components/ChatContainer';
import { logAction, sentMessageToIframe } from '../utils/utils';
import useMaximize from '../hooks/useMaximize';

const App = ({
  domain,
  formID,
  queryParams,
  background,
  isInitialOpen,
  isDraggable,
  username,
  variant,
  autoOpenChatIn,
  customizations,
  agentRenderURL
}) => {
  const iframeRef = useRef();
  const { isMaximized, closeMaximize } = useMaximize({ iframeRef });
  const formCopilotProps = StorageHelper.getLocalStorageItem({
    key: 'FormCopilotProps',
    defaultValue: {}
  });
  let offsetX = null;
  let offsetY = null;
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent);
  const containerRef = useRef();
  const [ABTestVariant, setABTestVariant] = useState(formCopilotProps.variant ?? '');

  const checkFormFooter = () => {
    const { isFormFooterVisible } = window;
    if (!isFormFooterVisible || !containerRef.current) return;
    containerRef.current.classList.add('withFormFooter');
  };

  const initialChatVisibility = variantCode => {
    if (isInitialOpen === true || isInitialOpen === false) {
      return isInitialOpen;
    }
    const isChatVisibilityState = formCopilotProps[`${formID}`];
    const isDefaultOpen = isChatVisibilityState ? formCopilotProps[`${formID}`].isOpened : true;
    const isChatDefaultClosedTest = variantCode === '28432' || variantCode === '28422';
    if (isChatVisibilityState) {
      return isDefaultOpen && !isMobile;
    }
    return isDefaultOpen && !isMobile && !isChatDefaultClosedTest;
  };

  if (variant === '28571' || window.location.href.includes('formAssistant=1')) {
    queryParams.push('title=Form Assistant');
  }

  const actualDomain = agentRenderURL ?? `${domain}/agent/${formID}`;
  const [isOpened, setIsOpened] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const avatarURL = `${actualDomain}/avatar-icon`;
  const iframeURL = `${actualDomain}?${queryParams.join('&')}&parentURL=${encodeURIComponent(window.location.href)}&embedMode=popover`;
  const hasTooltipBeenShown = useRef(false);
  const [loading, setLoading] = useState(true);
  const autoOpenChatTimeoutRef = useRef();

  const isAdmin = window.JotForm?.isAdmin ?? false;

  useEffect(() => {
    if (!isOpened && !hasTooltipBeenShown.current) {
      hasTooltipBeenShown.current = true;
      setShowTooltip(true);
      setTimeout(() => {
        setShowTooltip(false);
      }, 10000);
    } else {
      setShowTooltip(false);
    }
  }, [isOpened]);

  const onFirstAnswer = event => {
    if (event.origin !== window.location.origin || event.data.message?.source !== 'ai-agent') {
      return;
    }

    if (event.data.message && Object.hasOwn(event.data.message, 'isFirstAnswer')) {
      if (event.data.message.isFirstAnswer) {
        logAction({
          variant: ABTestVariant, actor: username, target: 'FormCopilotChat', action: 'haveReply'
        });
      }
    }
  };

  const shouldChatOpenAfterTimeout = variantCode => {
    const shouldChatOpen = initialChatVisibility(variantCode);
    if (shouldChatOpen) {
      setTimeout(() => {
        setIsOpened(shouldChatOpen);
      }, 300);
      return;
    }
    setIsOpened(shouldChatOpen);
  };

  const handleLoad = () => {
    setLoading(false);
    shouldChatOpenAfterTimeout(ABTestVariant);
  };

  useEffect(() => {
    if (!formCopilotProps?.variant) {
      const abTestManager = new ABTestManager({
        isTestEnabled: true,
        testName: 'formCopilotAvatarVisibility',
        urlParam: 'formCopilotAvatar',
        controlVariantCode: '28412',
        isMultipleTestVariant: true,
        testVariantCode: [{
          code: '28432',
          urlParam: 'formCopilotAvatarTooltip'
        }, {
          code: '28422',
          urlParam: 'formCopilotAvatarClosed'
        }],
        cacheVariantCodeAtLocalStorage: true,
        customUserChecks: {
          isNotAdmin: !isAdmin
        }
      });
      abTestManager
        .isTestVariant()
        .then(testVariant => {
          const [isTestVariant = false, variantCode = ''] = testVariant;
          if (isTestVariant) {
            setABTestVariant(variantCode);
            StorageHelper.setLocalStorageItem({ key: 'FormCopilotProps', value: { ...formCopilotProps, variant: variantCode }, ttl: 7200 * 1000 });
          }
        });
    }
  }, []);

  useEffect(() => {
    window.addEventListener('message', onFirstAnswer);
    return () => {
      window.removeEventListener('message', onFirstAnswer);
    };
  }, [ABTestVariant]);

  const onToggleClick = useCallback(() => {
    if (loading) return;
    clearTimeout(autoOpenChatTimeoutRef.current);

    if (isMaximized) {
      closeMaximize();
    }
    setIsOpened(prevState => {
      formCopilotProps[`${formID}`] = {
        isOpened: !prevState
      };
      StorageHelper.setLocalStorageItem({ key: 'FormCopilotProps', value: { ...formCopilotProps }, ttl: 7200 * 1000 });
      logAction({
        variant: ABTestVariant, actor: username, target: 'FormCopilotToggle', action: `chat${!prevState === true ? 'Opened' : 'Closed'}`
      });
      if (!prevState) {
        sentMessageToIframe(iframeRef, {
          action: 'focus-ai-input'
        });
      }
      return !prevState;
    });
  }, [formID, isMaximized, ABTestVariant, loading]);

  useEffect(() => {
    const shouldChatOpen = initialChatVisibility(ABTestVariant);
    if (autoOpenChatIn === undefined || shouldChatOpen || isOpened || loading) return;
    autoOpenChatTimeoutRef.current = setTimeout(() => {
      onToggleClick();
    }, autoOpenChatIn);
  }, [loading]);

  useEventListener('message', ({ data }) => {
    const { action = '' } = data || {};
    if (action === 'close-embed-agent') {
      onToggleClick();
    }
  });

  const onDrag = e => {
    if (e.clientX === 0 && e.clientY === 0) return; // Ignore invalid drag events

    const moveX = e.clientX - offsetX;
    const moveY = e.clientY - offsetY;
    // Update position as the element is dragged
    containerRef.current.style.position = 'absolute';
    containerRef.current.style.left = `${moveX}px`;
    containerRef.current.style.top = `${moveY}px`;
  };

  const onDragStart = e => {
    const rect = containerRef.current.getBoundingClientRect();
    offsetX = e.clientX - rect.left;
    offsetY = e.clientY - rect.top;
  };

  const onDragOver = e => {
    e.preventDefault(); // Required to allow dropping
  };

  const onDrop = e => {
    e.preventDefault();
    const moveX = e.clientX - offsetX;
    const moveY = e.clientY - offsetY;

    containerRef.current.style.position = 'absolute';
    containerRef.current.style.left = `${moveX}px`;
    containerRef.current.style.top = `${moveY}px`;
  };

  const agentDesignProps = {
    background
  };

  useEffect(() => {
    checkFormFooter();
  }, [isOpened, isMaximized]);

  const additionalClasses = [
    isMaximized || '',
    isOpened ? 'opened' : '',
    customizations?.position ? `align-${customizations.position}` : ''
  ];

  return (
    <div
      ref={containerRef}
      className={`embedded-agent-container ${additionalClasses.join(' ')}`}
      draggable={isDraggable}
      onDrag={onDrag}
      onDragStart={onDragStart}
      onDragOver={onDragOver}
      onDrop={onDrop}
    >
      <ChatButton
        avatarURL={avatarURL}
        agentDesignProps={agentDesignProps}
        onClick={onToggleClick}
        isTooltipShouldShown={showTooltip}
        customizations={customizations}
      />
      <ChatContainer
        iframeURL={iframeURL}
        iframeRef={iframeRef}
        isOpened={isOpened}
        loading={loading}
        handleLoad={handleLoad}
      />
    </div>
  );
};

App.propTypes = {
  domain: string,
  formID: string.isRequired,
  queryParams: array,
  background: string,
  isInitialOpen: bool,
  isDraggable: bool,
  username: string,
  variant: string,
  autoOpenChatIn: number,
  customizations: shape({
    background: string,
    buttonBackgroundColor: string,
    buttonIconColor: string
  }),
  agentRenderURL: string
};

App.defaultProps = {
  domain: 'https://www.jotform.com',
  queryParams: [],
  background: '',
  isDraggable: false,
  username: '',
  variant: '',
  autoOpenChatIn: undefined,
  agentRenderURL: undefined
};

export default App;
